module.exports = {
  domains: {
    account: process.env.GATSBY_ACCOUNT_DOMAIN || 'account-reg.kservicing.com',
    app: process.env.GATSBY_APP_DOMAIN || 'app.kservicing.com',
    kamex: process.env.GATSBY_KAMEX_DOMAIN || 'www.kabbage.com',
    sba: process.env.GATSBY_SBA_DOMAIN || 'www.sba.com', 
  },
  get appLogin() {
    return `https://${this.domains.app}/login/`;
  },
  get appLogout() {
    return `https://${this.domains.app}/logout/`;
  },
  supportPhone: '855-747-5135',
  supportEmail: 'support@kservicing.com',
  forgivenessSupport: 'pppforgiveness@kservicing.com',
  collectionsEmail: 'collections@paylater.kservicing.com',
  loanservicingEmail: 'cxpriority@kservicing.com',
  ChangeOfOwnership: 'changeofownership@kservicecorp.com',
  ppsEmail: 'support@pay-pps.com',
  sbaEmail: 'lrsc.servicing@sba.gov',
};
