import { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';

const useAppState = () => {
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [signedInCookies] = useCookies(['__k_app', '__k_signedIn']);

  useEffect(() => {
    setIsSignedIn(signedInCookies?.__k_app || signedInCookies?.__k_signedIn);
  }, [signedInCookies]);

  return [isSignedIn];
};

export default useAppState;
