import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { graphql, Link, useStaticQuery } from 'gatsby';

import styles from './footer.module.sass';

const Footer = ({ children }) => {
  const { privacy, tos, bankRel } = useStaticQuery(graphql`
    query FooterQuery {
      privacy: markdownRemark(frontmatter: { id: { eq: "privacy-policy" } }) {
        frontmatter {
          urlPath
        }
      }
      tos: markdownRemark(frontmatter: { id: { eq: "terms-of-service" } }) {
        frontmatter {
          urlPath
        }
      }
      bankRel: markdownRemark(
        frontmatter: { id: { eq: "bank-relationships" } }
      ) {
        frontmatter {
          urlPath
        }
      }
    }
  `);

  return (
    <footer className={styles.footer}>
      <div className="container">
        {children && (
          <ol className={styles.annotations}>
            {React.Children.map(children, (child) => {
              if (child.props.className?.split(' ')?.includes('no-decimal')) {
                return React.cloneElement(child, {
                  className: cx(child.props.className, styles.noDecimal),
                });
              }
              return child;
            })}
          </ol>
        )}
        <ul className={styles.footnotes}>
          <li>
          Kabbage Funding is a trademark of American Express, used by KServicing under license.
          </li>
          <li>
            &copy; {new Date().getFullYear()} Kabbage, Inc. d/b/a KServicing.  All rights reserved.
          </li>
        </ul>
        <ul className={styles.legal}>
          <li>
            <Link className={styles.footerLink} to={tos.frontmatter.urlPath}>
              Terms of Service
            </Link>
          </li>
          <li>
            <Link
              className={styles.footerLink}
              to={privacy.frontmatter.urlPath}
            >
              Privacy Policy
            </Link>
          </li>
          <li>
            <Link
              className={styles.footerLink}
              to={bankRel.frontmatter.urlPath}
            >
              Bank Relationships
            </Link>
          </li>
        </ul>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

Footer.defaultProps = {
  children: null,
};

export default Footer;
