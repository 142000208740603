import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

const SEO = ({ title: titleProp, description, urlPath, image }) => {
  const title = `${titleProp} | Kabbage Servicing`;
  const url = `https://www.kservicing.com${urlPath}`;

  return (
    <Helmet
      htmlAttributes={{
        lang: 'en-US',
      }}
      title={title}
      meta={[
        ...(description ? [{ name: 'description', content: description }] : []),
        {
          property: `og:locale`,
          content: 'en_US',
        },
        {
          property: `og:title`,
          content: title,
        },
        ...(description
          ? [
              {
                property: `og:description`,
                content: description,
              },
              {
                property: `twitter:description`,
                content: description,
              },
            ]
          : []),
        ...(url
          ? [
              {
                property: `og:url`,
                content: url,
              },
            ]
          : []),
        {
          property: `og:site_name`,
          content: 'Kabbage Servicing',
        },
        {
          property: `og:image`,
          content: image,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:image`,
          content: image,
        },
      ]}
    />
  );
};

SEO.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  urlPath: PropTypes.string,
  image: PropTypes.string,
};

SEO.defaultProps = {
  description: '',
  urlPath: '/',
  image:
    'https://s3.amazonaws.com/kabbage-media-user/blog/wp-content/uploads/2013/07/09191400/Kabbage-Instagram.png',
};

export default SEO;
