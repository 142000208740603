import React, { useState } from 'react';
import { Link } from 'gatsby';
import cx from 'classnames';
import OutsideClickHandler from 'react-outside-click-handler';

import useAppState from '../../../hooks/app-state';
import styles from './header.module.sass';
import { appLogin, appLogout } from '../../../utils/constants';

const Header = () => {
  const [isMenuExpanded, setIsMenuExpanded] = useState(false);
  const [isUserSignedIn] = useAppState();

  const handleMenuButtonClick = (e) => {
    e.preventDefault();
    setIsMenuExpanded((state) => !state);
  };

  const handleOutsideClick = () => {
    setIsMenuExpanded(false);
  };

  return (
    <header className={styles.header}>
      <div className={cx('max-width', styles.container)}>
        <nav className={styles.nav}>
          <Link className={styles.homeLink} to="/">
            <span className="visually-hidden">Kabbage Servicing - Home</span>
          </Link>
          <OutsideClickHandler
            onOutsideClick={handleOutsideClick}
            display="inline"
          >
            <button
              className={styles.menuButton}
              type="button"
              aria-controls="expandable-menu"
              aria-expanded={isMenuExpanded}
              onClick={handleMenuButtonClick}
            >
              <span className="visually-hidden">Menu</span>
            </button>
            <ul
              className={cx(styles.menu, {
                [styles.isExpanded]: isMenuExpanded,
              })}
              role="menu"
              id="expandable-menu"
            >
              {/* <li role="menuitem">
                <Link className={styles.menuLink} to="/ppp-loan-forgiveness/">
                  Loan Forgiveness
                </Link>
              </li>
              <li role="menuitem">
                <Link className={styles.menuLink} to="/resources/">
                  Forgiveness Resources
                </Link>
              </li>
              <li role="menuitem">
                <Link className={styles.menuLink} to="/resources/ppp-faq/">
                  FAQ
                </Link>
              </li> */}
              <li role="menuitem">
                <Link className={styles.menuLink} to="/support/contact/">
                  Contact Us
                </Link>
              </li>
              {/* <li role="menuitem">
                <a
                  className={cx(styles.menuLink, {
                    [styles.menuLinkHidden]: isUserSignedIn,
                  })}
                  href={appLogin}
                  target="_blank"
                  rel="noreferrer"
                >
                  Sign In
                </a>
                <a
                  className={cx(styles.menuLink, {
                    [styles.menuLinkHidden]: !isUserSignedIn,
                  })}
                  href={appLogout}
                  target="_blank"
                  rel="noreferrer"
                >
                  Sign Out
                </a>
              </li> */}
            </ul>
          </OutsideClickHandler>
        </nav>
      </div>
    </header>
  );
};

export default Header;
